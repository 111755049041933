import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { lastValueFrom, Observable, Subscription, tap } from 'rxjs';
import { Filter } from '@/app/core/models/user.model';
import { UserService } from '@/app/core/services/user.service';

import { BargeModalComponent } from './barge-modal/barge-modal.component';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private router = inject(Router);
  private cdkDialog = inject(Dialog);
  private userService = inject(UserService);
  selectedFilter$: Observable<Filter> = this.userService.getSelectedFilter();
  navigationSubscription!: Subscription;

  openDialog() {
    const dialogRef = this.cdkDialog.open<Filter>(BargeModalComponent);
    return lastValueFrom(
      dialogRef.closed.pipe(tap(() => this.userService.refreshCurrentUser())),
    );
  }
  redirectWithParams(route: string, dashboardType: string) {
    this.navigationSubscription = this.selectedFilter$.subscribe((filter) => {
      this.router.navigate([route], {
        queryParams: { dashboardType, filterId: filter.FILTERS_ID },
      });
    });
  }
  redirectToRoute(route: string, fragment?: string) {
    this.router.navigate([route], { fragment });
  }

  removeSubscriptions() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
